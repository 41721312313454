<template>
  <div class="container">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <v-row>
            <v-col class="" cols="12" sm="6">
              <md-card class="bg-success">
                <md-card-content>
                  <h5 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Your Order History
                  </h5>

                  <p class="card-description text-white">
                    These are the orders you placed from your suppliers. Click
                    view order to see the complete details or the arrow button
                    to see a quick snapshot.
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
              </md-card>
            </v-col>

            <v-col cols="12" sm="6"> </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="main_orders"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Orders</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template #expanded-item="{ item }">
              <td :colspan="headers.length">
                <div
                  v-if="
                    vendor_orders[item.id] != null &&
                      vendor_orders[item.id].length > 0
                  "
                  class="row "
                >
                  <div class="col-lg-9">
                    <div
                      v-for="(order, idx) in vendor_orders[item.id]"
                      :key="idx"
                      class="row "
                    >
                      <div class="col-md-2">
                        <v-img
                          :src="
                            supplier_logos[order.supplier] != null
                              ? baseurl + supplier_logos[order.supplier]
                              : '/media/bikelogo4.png'
                          "
                          height="55px"
                          width="55px"
                        >
                        </v-img>
                      </div>
                      <div class="col-md-3">
                        <h4 class="mb-1">{{ order.supplier_name }}</h4>
                      </div>
                      <div class="col-lg-3 ">
                        <div class="display-1">
                          Order #: {{ order.id }} <br />
                          Subtotal: {{ order.subtotal | dollar_format }}
                        </div>
                      </div>
                      <div class="col-lg-3 ">
                        <div class="display-1">
                          Status:
                          <span v-if="order.status != null">{{
                            order.status
                          }}</span>
                          <span v-else>Pending</span>
                        </div>
                      </div>
                      <v-divider class="mx-4" inset vertical></v-divider>
                    </div>
                  </div>
                  <div class="col-lg-2 ">
                    <div class="display-1">
                      Subtotal: {{ item.subtotal | dollar_format }}
                    </div>
                    <div class="display-1">
                      Shipping: {{ item.shipping_amount | dollar_format }}
                    </div>
                    <v-divider class="mx-4" inset></v-divider>
                    <div class="display-1">
                      Total : {{ item.total | dollar_format }}
                    </div>
                    <v-btn
                      class="mt-4"
                      medium
                      outlined
                      @click="$router.push('/store/order-detail/' + item.id)"
                    >
                      View Order
                    </v-btn>
                  </div>
                </div>
              </td>
            </template>
            <template #item="{ item, expand, isExpanded }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.po_number }}
                </td>
                <td>
                  {{ item.created_at | date_format }}
                </td>
                <td>
                  {{ item.supplier_company_name }}
                </td>
                <td>
                  {{ item.user_name }}
                </td>
                <td>
                  {{ item.total | dollar_format }}
                </td>
                <td>
                  {{ item.status }}
                </td>
                <td>
                  <v-btn
                    small
                    text
                    class="mr-2"
                    @click="$router.push('/store/order-detail/' + item.id)"
                  >
                    View
                  </v-btn>
                </td>
                <td>
                  <v-icon @click="expand(!isExpanded)"> mdi-menu-down </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    supplier_logos: {},
    vendor_orders: {},
    expanded: [],
    singleExpand: false,
    dialog: false,
    headers: [
      {
        text: "Order ID",
      },
      { text: "PO number" },
      { text: "Date" },
      { text: "Supplier Name" },
      { text: "User" },
      { text: "Total $" },
      { text: "Status" },
      { text: "Actions" },
      { text: "", value: "data-table-expand" },
    ],
  }),
  apollo: {
    // main_orders: {
    //   query: gql`
    //     query MainOrderQuery {
    //       main_orders {
    //         id
    //         created_at
    //         company_name
    //         user_name
    //         subtotal
    //         shipping_amount
    //         total
    //         status
    //         supplier_company_name
    //         po_number
    //       }
    //     }
    //   `,
    //   client: "ecomClient",
    //   skip() {
    //     return this.$store.state.auth.user.company_relation == null;
    //   },
    // },
    // seller_vendor_orders: {
    //   client: "ecomClient",
    //   query: gql`
    //     query VendorOrdersQuery {
    //       seller_vendor_orders {
    //         id
    //         main_order_id
    //         supplier
    //         supplier_name
    //         status
    //         subtotal
    //         shipping_amount
    //         grand_total
    //       }
    //     }
    //   `,
    //   skip() {
    //     return this.$store.state.auth.user.company_relation == null;
    //   },
    // },
  },
  created() {
    setTimeout(() => {
      this.getVendorOrders();
    }, 1000);
  },
  methods: {
    ...mapActions(["getCompanyInfo"]),
    async getVendorOrders() {
      await this.$apollo.queries.main_orders.refetch();
      await this.$apollo.queries.seller_vendor_orders.refetch();
      let t_vendors = this.seller_vendor_orders;
      let t_vendor_orders = {},
        supplier_lists = {};
      this.supplier_logos = {};
      for (let i in t_vendors) {
        if (!(t_vendors[i].supplier in supplier_lists)) {
          supplier_lists[t_vendors[i].supplier] = 1;

          let res = await this.getCompanyInfo({
            id: t_vendors[i].supplier,
          });
          if (res.data.order_logo != null) {
            this.supplier_logos[t_vendors[i].supplier] =
              res.data.order_logo.url;
          }
        }

        if (!(t_vendors[i].main_order_id in t_vendor_orders)) {
          t_vendor_orders[t_vendors[i].main_order_id] = [];
        }
        t_vendor_orders[t_vendors[i].main_order_id].push(t_vendors[i]);
      }
      this.vendor_orders = t_vendor_orders;
    },
  },
};
</script>

<style scoped>
.main-raised {
  margin: -120px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
</style>

<style lang="scss"></style>
