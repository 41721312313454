var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"section-shaped my-0 skew-separator skew-mini "},[_c('v-img',{staticClass:"page-header header-filter  header-small ",attrs:{"max-height":"390px","src":"/media/test-biz-header.png","gradient":"to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"}})],1),_c('div',{staticClass:"main main-raised",staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('md-card',{staticClass:"bg-success"},[_c('md-card-content',[_c('h5',{staticClass:"card-category card-category-social text-white"},[_c('i',{staticClass:"far fa-newspaper"}),_vm._v(" Your Order History ")]),_c('p',{staticClass:"card-description text-white"},[_vm._v(" These are the orders you placed from your suppliers. Click view order to see the complete details or the arrow button to see a quick snapshot. ")]),_c('div',{staticClass:"card-stats text-center"})])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.main_orders,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[(
                  _vm.vendor_orders[item.id] != null &&
                    _vm.vendor_orders[item.id].length > 0
                )?_c('div',{staticClass:"row "},[_c('div',{staticClass:"col-lg-9"},_vm._l((_vm.vendor_orders[item.id]),function(order,idx){return _c('div',{key:idx,staticClass:"row "},[_c('div',{staticClass:"col-md-2"},[_c('v-img',{attrs:{"src":_vm.supplier_logos[order.supplier] != null
                            ? _vm.baseurl + _vm.supplier_logos[order.supplier]
                            : '/media/bikelogo4.png',"height":"55px","width":"55px"}})],1),_c('div',{staticClass:"col-md-3"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(order.supplier_name))])]),_c('div',{staticClass:"col-lg-3 "},[_c('div',{staticClass:"display-1"},[_vm._v(" Order #: "+_vm._s(order.id)+" "),_c('br'),_vm._v(" Subtotal: "+_vm._s(_vm._f("dollar_format")(order.subtotal))+" ")])]),_c('div',{staticClass:"col-lg-3 "},[_c('div',{staticClass:"display-1"},[_vm._v(" Status: "),(order.status != null)?_c('span',[_vm._v(_vm._s(order.status))]):_c('span',[_vm._v("Pending")])])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)}),0),_c('div',{staticClass:"col-lg-2 "},[_c('div',{staticClass:"display-1"},[_vm._v(" Subtotal: "+_vm._s(_vm._f("dollar_format")(item.subtotal))+" ")]),_c('div',{staticClass:"display-1"},[_vm._v(" Shipping: "+_vm._s(_vm._f("dollar_format")(item.shipping_amount))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":""}}),_c('div',{staticClass:"display-1"},[_vm._v(" Total : "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"medium":"","outlined":""},on:{"click":function($event){return _vm.$router.push('/store/order-detail/' + item.id)}}},[_vm._v(" View Order ")])],1)]):_vm._e()])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
                            var expand = ref.expand;
                            var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.po_number)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("date_format")(item.created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.supplier_company_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.status)+" ")]),_c('td',[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$router.push('/store/order-detail/' + item.id)}}},[_vm._v(" View ")])],1),_c('td',[_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" mdi-menu-down ")])],1)])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }